import { useState } from "react"
import styled from "styled-components"

import { useTimeout } from "usehooks-ts"

import { LogOutput } from "src/components/RemoteDebugService/LogOutput"
import {
  useAnnounceWs,
  useBrokerWs as useBrokerCommand,
  useDeviceCommand,
  useDeviceDataTunnel,
} from "src/components/RemoteDebugService/minutWsHooks"
import {
  DeviceCommand,
  IBrokerResponse,
  ICmdMsg,
} from "src/components/RemoteDebugService/minutWsProtocol"
import {
  AttachRemoteDebug,
  DeviceCommands,
} from "src/components/RemoteDebugService/RemoteDeviceCommands"
import { SerialDeviceConnectButton } from "src/components/RemoteDebugService/SerialDeviceConnectButton"
import { useSerial } from "src/components/RemoteDebugService/useSerial"
import { ChatDemo } from "src/components/Sandbox/ChatDemo"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { MDetails } from "src/ui/ExpandableSection/MDetails"
import { MainView } from "src/ui/Layout/MainView"
import { MBanner } from "src/ui/MBanner/MBanner"
import { Logger } from "src/utils/logger"

const debug = new Logger({ prefix: "[RemoteDeviceDevTool]:" })

export function RemoteDeviceDevTool() {
  return (
    <MainView title="Remote device debugging">
      <RemoteDeviceDev />
    </MainView>
  )
}

function RemoteDeviceDev() {
  const { user_id: uid } = useGetUser()

  const { isConnected: serialConnected, setAutoConnectEnabled } = useSerial()
  const [deviceCmdUri, setDeviceCmdUri] = useState("")
  const [deviceDataUri, setDeviceDataUri] = useState("")
  const [log, setLog] = useState<string[]>([])
  const [chatOpen, setChatOpen] = useState(false)
  const [backendError, setBackendError] = useState<null | React.ReactNode>(null)
  const [waitingForBEReply, setWaitingForBEReply] = useState<number | null>(
    null
  )

  useEffectOnce(() => {
    setAutoConnectEnabled(true) // Auto-connect to device once plugged in
  })

  function logMessage(s: string | null) {
    s != null && setLog((log) => [...log, `${s}\n`])
  }

  const brokerCmd = useBrokerCommand({
    uid,
    connect: serialConnected,
    onMessage: function onBrokerMessage(m: IBrokerResponse) {
      debug.log("broker message", m)
      logMessage(getConsoleMessage(m, uid))
    },
    onBrokerConnect(data) {
      debug.log(data)
      setDeviceCmdUri(data.cmd)
      setDeviceDataUri(data.data)
      setWaitingForBEReply(null)
      setBackendError(null)
    },
  })

  const deviceCmd = useDeviceCommand({
    uid,
    uri: deviceCmdUri,
    onMessage(r) {
      logMessage(JSON.stringify(r.data, null, 2))
    },
    onError(r) {
      logMessage(JSON.stringify(r, null, 2))
    },
    onDeviceDataUri(s) {
      setDeviceDataUri(s)
    },
  })

  // Setup tunnel between device and Minut Debug Service once a URI is available
  useDeviceDataTunnel({ uid, uri: deviceDataUri })

  // Setup announce channel
  useAnnounceWs({
    connect: true,
    onMessage(msg) {
      logMessage(msg)
    },
  })

  useTimeout(() => {
    setBackendError(
      "Unable to connect to debug service. Please try again later!"
    )
    setWaitingForBEReply(null)
  }, waitingForBEReply)

  function handleLocalDeviceConnect() {
    setWaitingForBEReply(4000)
  }

  function handleLocalDeviceDisconnect() {
    logMessage(`[Disconnected from device]`)
    brokerCmd.getWebSocket()?.close()
    setDeviceCmdUri("")
    setDeviceDataUri("")
    setBackendError(null)
    setWaitingForBEReply(null)
  }

  function handleAttached(cmdUri: string) {
    setDeviceCmdUri(cmdUri)
    deviceCmd.sendRequest(DeviceCommand.GET_DATA_CHANNEL)
  }

  function handleDeviceCommand(cmd: DeviceCommand, args?: string) {
    logMessage(`> ${cmd} ${args ?? ""}`)
    deviceCmd.sendRequest(cmd, args)
  }

  return (
    <RemoteDeviceDevBox>
      <TopBar>
        <AttachRemoteDebug onSubmit={handleAttached} />
        <SerialDeviceConnectButton
          loading={!!waitingForBEReply}
          onConnected={handleLocalDeviceConnect}
          onDisconnected={handleLocalDeviceDisconnect}
        />
      </TopBar>

      <MBanner key="errorbox" show={!!backendError}>
        {backendError}
      </MBanner>

      <MDetails
        id="debug-chat"
        title="Chat"
        open={chatOpen}
        onChange={() => setChatOpen(!chatOpen)}
      >
        <ChatDemo />
      </MDetails>

      <LogOutput data={log} onClear={() => setLog([])} rows={20} />

      <DeviceCommands
        deviceDataUri={deviceDataUri}
        onDeviceCommand={handleDeviceCommand}
        loading={!!deviceCmd.reqId}
      />

      <ExpandableSection id="connection-info" title="Connection information">
        <div>Device cmd uri: {deviceCmdUri}</div>
        <div>Device data uri: {deviceDataUri}</div>
        <div>Serial: {String(serialConnected)}</div>
        <div>
          Broker: {brokerCmd.brokerReqState === "connected" ? "true" : "false"}
        </div>
      </ExpandableSection>
    </RemoteDeviceDevBox>
  )
}

const RemoteDeviceDevBox = styled.div`
  display: grid;
  gap: 1rem;
`

const TopBar = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`

function getConsoleMessage(msg: ICmdMsg, webAppId: string): string | null {
  switch (msg.dest) {
    case webAppId: {
      return `< ${JSON.stringify(msg, null, 2)}\n`
    }
    default:
      return `> ${JSON.stringify(msg, null, 2)}\n`
  }
}
